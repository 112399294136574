import "./App.css";
import { Camera } from "./components/camera";
import { GameStage } from "./components/game-stage/game-stage";

function App() {
  return (
    <div className="relative w-full h-full">
      <GameStage />
      <Camera showCanvas={false} />
    </div>
  );
}

export default App;
