import { FC } from "react";
import { IPosition } from "../../../common/models";

interface IProp {
  position: IPosition;
}

export const PlayerPointer: FC<IProp> = (props) => {
  return (
    <div
      className="absolute translate-x-[-50%] translate-y-[-50%]"
      style={{ left: `${props.position.x}px`, top: `${props.position.y}px` }}
    >
      <div className="w-7 h-10 rounded-full absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
        <svg
          className="fill-white/80"
          viewBox="0 0 800 1097"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M637.269 501.733L627.766 100.526C626.96 65.5135 600.346 37.8765 568.175 38.64C536.188 39.4072 510.718 68.2581 511.569 103.304L520.047 462.198C520.308 473.775 511.819 483.423 501.251 483.643C490.429 483.902 481.475 474.679 481.214 463.103L471.725 61.9525C470.888 27.107 444.294 -0.723054 412.124 0.0238858C379.926 0.800082 354.691 29.8707 355.517 64.6883L365.01 465.913C365.275 477.417 356.77 487.093 345.966 487.336C335.432 487.594 326.481 478.382 326.187 466.814L318.705 150.17C317.873 115.106 291.087 87.4793 259.103 88.2411C226.933 89.0046 201.665 117.842 202.493 152.913L214.973 680.668L133.583 565.401C109.418 527.016 63.622 514.894 30.8596 538.175C-1.75424 561.767 -8.71498 611.873 15.1361 650.32C15.1361 650.32 146.69 855.917 202.794 943.912C258.862 1032.16 347.997 1099.94 511.578 1096.06C782.315 1089.64 801.14 861.47 799.536 793.428L786.065 223.404C785.24 188.584 758.412 160.786 726.237 161.556C694.236 162.315 668.791 191.348 669.604 226.17L676.1 500.834C676.366 512.339 667.923 522.033 657.289 522.289C646.479 522.527 637.539 513.247 637.272 501.742L637.269 501.733Z" />
        </svg>
      </div>
    </div>
  );
};
