import { useRive } from "@rive-app/react-canvas";
import { useEffect } from "react";
import { riveFile, useGameStore } from "../game-store";
import { GameObject } from "./game-object";

export const GameObjectScene = () => {
  const { allGameObjects, scene } = useGameStore();

  const { rive, RiveComponent } = useRive({
    src: riveFile,
    autoplay: true,
    artboard: "Green_Forest_Scene_1",
  });

  useEffect(() => {
    rive?.reset({ artboard: scene, autoplay: true });
  }, [rive, scene]);

  return (
    <>
      {allGameObjects.map((item, index) => {
        return <GameObject key={item.artboard} {...item} />;
      })}

      <RiveComponent className="w-full h-full absolute left-0 top-0" />
    </>
  );
};
