import { IGameObject, IScene } from "../../common/models";

export type GameObjectArt =
  | "PūririMoth"
  | "Monarch_Butterfly"
  | "Red_Admiral"
  | "Riroriro"
  | "Tui"
  | "Tauhou"
  | "Kereru"
  | "Flax"
  | "YellowFlower"
  | "GoldenBranch.png"
  | "Berry"
  | "WhiteFlower"
  | "Tiaki"
  | "Piko"
  | "Fernie.svg"
  | "Kaha.svg"
  | "Kowhai.svg"
  | "Pepeketua.psd"
  | "Morepork.png"
  | "Tahi & Rua"
  | "Ora"
  | "Heli";

export const allGameObjects: Array<IGameObject> = [
  {
    artboard: "PūririMoth",
    height: 382.3 * 0.35,
    width: 417.55 * 0.35,
    isFound: false,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230551/game%20objects/Moth_d7hmpw.png",
    isHover: false,
    position: {
      x: 1400.48,
      y: 582,
    },
  },
  {
    artboard: "Monarch_Butterfly",
    height: 322.21 * 0.5,
    width: 324.02 * 0.5,
    isFound: false,
    isHover: false,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230551/game%20objects/Monarch_c7qber.png",
    position: {
      x: 825.38,
      y: 510.76,
    },
  },
  {
    artboard: "Red_Admiral",
    isHover: false,
    height: 259.36 * 0.5,
    width: 308.45 * 0.5,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230553/game%20objects/RedAdmiral_uan0jt.png",
    isFound: false,
    position: {
      x: 419.83,
      y: 392.19,
    },
  },
  {
    artboard: "Riroriro",
    height: 605 * 0.18,
    width: 673 * 0.18,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230554/game%20objects/Riroriro_glrroq.png",
    isFound: false,
    isHover: false,
    position: {
      x: 505.86,
      y: 221.02,
    },
  },
  {
    artboard: "Tui",
    height: 1087 * 0.18,
    width: 1112 * 0.18,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230552/game%20objects/Tui_cndb40.png",
    isFound: false,
    isHover: false,
    position: {
      x: 87,
      y: 258,
    },
  },
  {
    artboard: "Tauhou",
    height: 728 * 0.19,
    width: 833 * 0.19,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230553/game%20objects/Tauhou_omkdam.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1044.93,
      y: 398.74,
    },
  },
  {
    artboard: "Kereru",
    height: 2286.11 * 0.15,
    width: 2317.81 * 0.15,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230551/game%20objects/Kereru_tdtcox.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1562,
      y: 202,
    },
  },
  {
    artboard: "Flax",
    height: 1815.31 * 0.18,
    width: 1892.88 * 0.18,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230550/game%20objects/Flax_dlhftq.png",
    isFound: false,
    isHover: false,
    position: {
      x: 921,
      y: 556,
    },
  },
  {
    artboard: "YellowFlower",
    height: 252.45 * 0.5,
    width: 247.43 * 0.5,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230552/game%20objects/YellowFlower_ydhvlo.png",
    isFound: false,
    isHover: false,
    position: {
      x: 960,
      y: 757,
    },
  },
  {
    artboard: "GoldenBranch.png",
    height: 211.25 * 0.5,
    width: 294.48 * 0.5,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230552/game%20objects/golden_branch_w8ohnb.png",
    isFound: false,
    isHover: false,
    position: {
      x: 774,
      y: 294,
    },
  },
  {
    artboard: "Berry",
    height: 167.53 * 0.5,
    width: 156.06 * 0.5,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230551/game%20objects/berry_kz1kep.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1178.58,
      y: 174.31,
    },
  },
  {
    artboard: "WhiteFlower",
    height: 263.84 * 0.5,
    width: 161.23 * 0.5,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664230552/game%20objects/WhiteFlower_hjtezt.png",
    isFound: false,
    isHover: false,
    position: {
      x: 596,
      y: 695,
    },
  },
  {
    artboard: "Tiaki",
    height: 213.91,
    width: 207.71,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664257448/game%20objects/Tiaki_udjy0i.png",
    isFound: false,
    isHover: false,
    position: {
      x: 98.12,
      y: 244.54,
    },
  },
  {
    artboard: "Piko",
    height: 236.15,
    width: 129.07,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664257448/game%20objects/Piko_ytauo4.png",
    isFound: false,
    isHover: false,
    position: {
      x: 991.53,
      y: 667.23,
    },
  },
  {
    artboard: "Fernie.svg",
    height: 246.28,
    width: 166.05,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664257448/game%20objects/Fernie_ubc7dn.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1074.14,
      y: 268.51,
    },
  },
  {
    artboard: "Kaha.svg",
    height: 169.95,
    width: 172.43,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664257448/game%20objects/Kaha_nsrfef.png",
    isFound: false,
    isHover: false,
    position: {
      x: 506.19,
      y: 657.75,
    },
  },
  {
    artboard: "Kowhai.svg",
    height: 295.93,
    width: 185.2,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664257448/game%20objects/Kowhai_gxhiog.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1577.64,
      y: 587.57,
    },
  },
  {
    artboard: "Pepeketua.psd",
    height: 205.6,
    width: 224.97,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1664498082/game%20objects/Pepeketua__g5re6j.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1663.79,
      y: 791.4,
    },
  },
  {
    artboard: "Morepork.png",
    height: 180,
    width: 110,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1666304890/game%20objects/Morepork_OG_hdasmk.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1663.79,
      y: 791.4,
    },
  },
  {
    artboard: "Tahi & Rua",
    height: 201,
    width: 275,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1666307676/game%20objects/Tahi_Rua_szn0zq.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1663.79,
      y: 791.4,
    },
  },
  {
    artboard: "Ora",
    height: 223,
    width: 184,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1666307675/game%20objects/Ora_cofrja.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1663.79,
      y: 791.4,
    },
  },
  {
    artboard: "Heli",
    height: 301,
    width: 208,
    filledImage:
      "https://res.cloudinary.com/te-wao-nui/image/upload/v1666307675/game%20objects/Heli_kbbp1o.png",
    isFound: false,
    isHover: false,
    position: {
      x: 1663.79,
      y: 791.4,
    },
  },
];

export const getArtboard = (name: GameObjectArt): IGameObject => {
  return allGameObjects.filter((item) => item.artboard === name)[0];
};

export const scenes: Array<IScene> = [
  {
    background: "Scene 3",
    scene: "Night_Scene_5",
    allGameObjects: [
      {
        ...getArtboard("Red_Admiral"),
        position: {
          x: 45,
          y: 228,
        },
        width: 178,
        height: 170,
      },
      {
        ...getArtboard("Riroriro"),
        position: {
          x: 573,
          y: 380,
        },
        width: 147,
        height: 143,
      },
      {
        ...getArtboard("GoldenBranch.png"),
        position: {
          x: 1304,
          y: 371,
        },
        width: 167,
        height: 115,
      },
      {
        ...getArtboard("Monarch_Butterfly"),
        position: {
          x: 1566,
          y: 143,
        },
        width: 189,
        height: 178,
      },
      {
        ...getArtboard("Pepeketua.psd"),
        position: {
          x: 1604,
          y: 818,
        },
        width: 284,
        height: 245,
      },
    ],
  },
  {
    background: "Scene 3",
    scene: "Night_Scene_4",
    allGameObjects: [
      {
        ...getArtboard("Morepork.png"),
        position: {
          x: 628,
          y: 92,
        },
        width: 122,
        height: 166,
      },
      {
        ...getArtboard("WhiteFlower"),
        position: {
          x: 351,
          y: 562,
        },
        width: 132,
        height: 158,
      },
      {
        ...getArtboard("Tauhou"),
        position: {
          x: 1117,
          y: 473,
        },
        width: 127,
        height: 133,
      },
      {
        ...getArtboard("PūririMoth"),
        position: {
          x: 1562,
          y: 860,
        },
        width: 200,
        height: 184,
      },
      {
        ...getArtboard("Berry"),
        position: {
          x: 1714,
          y: 167,
        },
        width: 95,
        height: 95,
      },
    ],
  },
  {
    background: "Scene 3",
    scene: "Night_Scene_3",
    allGameObjects: [
      {
        ...getArtboard("Tui"),
        position: {
          x: 165,
          y: 264,
        },
        width: 158,
        height: 224,
      },
      {
        ...getArtboard("Flax"),
        position: {
          x: 267,
          y: 466,
        },
        width: 211,
        height: 228,
      },
      {
        ...getArtboard("Pepeketua.psd"),
        position: {
          x: 218,
          y: 804,
        },
        width: 264,
        height: 228,
      },
      {
        ...getArtboard("Morepork.png"),
        position: {
          x: 1511,
          y: 182,
        },
        width: 144,
        height: 206,
      },
      {
        ...getArtboard("YellowFlower"),
        position: {
          x: 1143,
          y: 454,
        },
        width: 95,
        height: 100,
      },
    ],
  },
  {
    background: "Scene 3",
    scene: "Night_Scene_2",
    allGameObjects: [
      {
        ...getArtboard("Kaha.svg"),
        position: {
          x: 173,
          y: 246,
        },
        width: 192,
        height: 201,
      },
      {
        ...getArtboard("Piko"),
        position: {
          x: 912,
          y: 362,
        },
        width: 145,
        height: 231,
      },
      {
        ...getArtboard("Kowhai.svg"),
        position: {
          x: 1680,
          y: 65,
        },
        width: 200,
        height: 291,
      },
      {
        ...getArtboard("Fernie.svg"),
        position: {
          x: 85,
          y: 622,
        },
        width: 177,
        height: 210,
      },
      {
        ...getArtboard("Tiaki"),
        position: {
          x: 1595,
          y: 767,
        },
        width: 185,
        height: 204,
      },
    ],
  },
  {
    background: "Scene 3",
    scene: "Night_Scene_1",
    allGameObjects: [
      {
        ...getArtboard("Tiaki"),
        position: {
          x: 254,
          y: 172,
        },
        width: 210,
        height: 218,
      },
      {
        ...getArtboard("Heli"),
        position: {
          x: 935,
          y: 72,
        },
        width: 252,
        height: 311,
      },
      {
        ...getArtboard("Ora"),
        position: {
          x: 1523,
          y: 126,
        },
        width: 219,
        height: 239,
      },
      {
        ...getArtboard("Fernie.svg"),
        position: {
          x: 881,
          y: 692,
        },
        width: 181,
        height: 222,
      },
      {
        ...getArtboard("Tahi & Rua"),
        position: {
          x: 1305,
          y: 671,
        },
        width: 301,
        height: 264,
      },
    ],
  },
  {
    background: "Final_Scene",
    scene: "Green_Forest_Scene_1",
    allGameObjects: [
      getArtboard("Red_Admiral"),
      getArtboard("WhiteFlower"),
      getArtboard("Flax"),
      getArtboard("Tauhou"),
      getArtboard("Kereru"),
    ],
  },
  {
    background: "Final_Scene",
    scene: "Green_Forest_Scene_2",
    allGameObjects: [
      getArtboard("Tui"),
      getArtboard("Riroriro"),
      getArtboard("Monarch_Butterfly"),
      getArtboard("YellowFlower"),
      getArtboard("Tauhou"),
    ],
  },
  {
    background: "Final_Scene",
    scene: "Green_Forest_Scene_3",
    allGameObjects: [
      getArtboard("Tui"),
      getArtboard("Red_Admiral"),
      getArtboard("Berry"),
      getArtboard("Kereru"),
      getArtboard("GoldenBranch.png"),
    ],
  },
  {
    background: "Final_Scene",
    scene: "Green_Forest_Scene_4",
    allGameObjects: [
      getArtboard("PūririMoth"),
      getArtboard("Monarch_Butterfly"),
      getArtboard("Tauhou"),
      getArtboard("Riroriro"),
      getArtboard("Tui"),
    ],
  },
  {
    background: "Scene 2",
    scene: "Waterfall_Scene_1",
    allGameObjects: [
      {
        ...getArtboard("Monarch_Butterfly"),
        position: { x: 80.19, y: 131.99 },
        width: 216.23,
        height: 259.18,
      },

      {
        ...getArtboard("Flax"),
        position: { x: 378.68, y: 540 },
        width: 307.44,
        height: 258.66,
      },
      {
        ...getArtboard("Riroriro"),
        position: { x: 720.6, y: 724.25 },
        width: 159.55,
        height: 129,
      },
      {
        ...getArtboard("WhiteFlower"),
        position: { x: 997.88, y: 638.64 },
        width: 143.41,
        height: 173.57,
      },
      getArtboard("Pepeketua.psd"),
    ],
  },
  {
    background: "Scene 2",
    scene: "Waterfall_Scene_2",
    allGameObjects: [
      {
        ...getArtboard("Red_Admiral"),
        position: { x: 74.49, y: 131.6 },
        width: 224.97,
        height: 205.6,
      },
      {
        ...getArtboard("Tui"),
        position: { x: 523.72, y: 38.95 },
        width: 224.97,
        height: 205.6,
      },
      {
        ...getArtboard("YellowFlower"),
        position: { x: 459.17, y: 540 },
        width: 130,
        height: 150,
      },
      {
        ...getArtboard("Tauhou"),
        position: { x: 1565.22, y: 310.34 },
        width: 157.4,
        height: 150,
      },
      {
        ...getArtboard("PūririMoth"),
        position: { x: 1659.78, y: 836.35 },
        width: 170,
        height: 190,
      },
    ],
  },
  {
    background: "Scene 2",
    scene: "Waterfall_Scene_3",
    allGameObjects: [
      {
        ...getArtboard("Tiaki"),
        position: { x: 1000, y: 179 },
        width: 229,
        height: 250,
      },
      {
        ...getArtboard("Kaha.svg"),
        position: { x: 179, y: 313 },
        width: 190,
        height: 180,
      },
      {
        ...getArtboard("Fernie.svg"),
        position: { x: 1543, y: 546 },
        width: 162,
        height: 245,
      },
      {
        ...getArtboard("Piko"),
        position: { x: 1055, y: 651 },
        width: 155,
        height: 278,
      },
      {
        ...getArtboard("Kowhai.svg"),
        position: { x: 1646, y: 98 },
        width: 190,
        height: 314,
      },
    ],
  },
];
