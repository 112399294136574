import { useEffect } from "react";
import { BackgroundScene } from "./background/background-scene";
import { GameBar } from "./game-bar/game-bar";
import { GameObjectScene } from "./game-object/game-object-scene";
import { resetGame } from "./game-store";
import { PlayerScene } from "./player/player-scene";
import { Screensaver } from "./screensaver/screensaver";

export const GameStage = () => {
  useEffect(() => {
    resetGame();
  }, []);

  return (
    <div className="absolute left-0 top-0 w-full h-full overflow-hidden z-0">
      <BackgroundScene />
      <GameObjectScene />
      <PlayerScene />
      <GameBar />
      <Screensaver />
    </div>
  );
};
