import { useEffect, useRef, useState } from "react";
import { useBodyPoseState } from "../../pose-detection/pose-detection";

export const Screensaver = () => {
  const ref = useRef<HTMLVideoElement>(null);

  const { pointers } = useBodyPoseState();

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.play();
    }, 3000);
  }, [ref]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (pointers.length === 0) {
      timer = setTimeout(() => {
        setIsVisible(() => pointers.length === 0);
      }, 10000);
    } else {
      setIsVisible(false);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [pointers]);

  if (!isVisible) {
    return null;
  } else {
    return (
      <div className="w-full h-full left-0 top-0 bg-black/60 z-30 absolute backdrop-blur-md">
        <div className="flex justify-center flex-grow h-full items-center flex-col gap-8">
          <div className="text-white text-center text-5xl font-bold  flex flex-col gap-2">
            <h2 className="text-2xl drop-shadow-lg shadow-red-400">
              Welcome to Te Wao Nui and Wellington Hospitals Foundation
            </h2>
            <h2 className="drop-shadow-lg shadow-white uppercase">
              TREE OF LiFE Journey
            </h2>
          </div>
          <video ref={ref} autoPlay loop className="w-[60%] rounded-2xl">
            <source src="https://res.cloudinary.com/te-wao-nui/video/upload/v1664235994/Tutorial_azvixy.mp4" />
          </video>
        </div>
      </div>
    );
  }
};
