import { resetGame, useGameStore } from "../game-store";
import { ResetCounter } from "./reset-counter";

export const GameBar = () => {
  const { isGameOver, allGameObjects } = useGameStore();

  const onResetCounterComplete = () => {
    resetGame();
  };

  return (
    <div className="absolute flex items-center w-full p-5 left-0 bottom-0">
      {
        isGameOver ? <ResetCounter onComplete={onResetCounterComplete} /> : null
        // <ResetCounter onComplete={() => {}} />
      }
      <div className="grow flex flex-col items-center">
        <div className="flex gap-7 items-center bg-white/20 p-4 rounded-3xl backdrop-blur-md">
          {allGameObjects.map((obj, index) => {
            return (
              <img
                src={obj.filledImage}
                key={index}
                alt={obj.artboard}
                style={{
                  filter: obj.isFound ? "" : "grayscale(100%) brightness(100)",
                }}
                width="80"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
