import { FC, useEffect, useState } from "react";
import { IPosition } from "../../../common/models";
import { useBodyPoseState } from "../../pose-detection/pose-detection";
import { updateFound } from "../game-store";

interface IProp {
  position: IPosition;
  width: number;
  height: number;
  artboard: string;
  isFound: boolean;
  isHover: boolean;
}

export const GameObject: FC<IProp> = (props) => {
  const { pointers } = useBodyPoseState();

  const [position, setPos] = useState<IPosition | null>(null);

  const onProgressComplete = () => {
    updateFound(props.artboard);
  };

  useEffect(() => {
    let _pos: IPosition | null = null;

    for (let index = 0; index < pointers.length; index++) {
      const pos = pointers[index];

      const left = props.position.x;
      const right = props.position.x + props.width;
      const top = props.position.y;
      const bottom = props.position.y + props.height;
      const contains =
        pos.x > left && pos.x < right && pos.y > top && pos.y < bottom;

      if (contains && !props.isFound) {
        _pos = pos;
        break;
      }
    }

    setPos(_pos);
  }, [
    pointers,
    props.height,
    props.isFound,
    props.position.x,
    props.position.y,
    props.width,
  ]);

  return (
    <div
      className="absolute"
      style={{
        height: `${props.height}px`,
        width: `${props.width}px`,
        left: `${props.position.x}px`,
        top: `${props.position.y}px`,
      }}
    >
      {props.isFound ? (
        <div className="w-[70%] h-[70%] bg-white/80 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] blur-[20px] rounded-full"></div>
      ) : null}

      {position && !props.isFound ? (
        <div className="w-[90px] h-[90px] left-[50%] top-[50%] absolute translate-x-[-50%] translate-y-[-50%]">
          <div className="w-full h-full bg-white/40 absolute rounded-full border border-white hello"></div>
          <div className="w-full h-full bg-white/30 absolute rounded-full border border-white hello-md"></div>
        </div>
      ) : null}

      {position && !props.isFound ? (
        <svg
          width="80"
          height="80"
          viewBox="0 0 110 110"
          className="fixed z-40"
          style={{
            left: `${position.x - 40}px`,
            top: `${position.y - 40}px`,
          }}
        >
          <circle
            cx="55"
            cy="55"
            r="50"
            onAnimationEnd={() => {
              onProgressComplete();
            }}
            strokeLinecap="round"
            strokeWidth={10}
            className={`animated stroke-white fill-transparent show-progress`}
          />
        </svg>
      ) : null}
    </div>
  );
};
