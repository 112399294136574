import { useRive } from "@rive-app/react-canvas";
import { useEffect } from "react";
import Confetti from "react-confetti";
import { riveFile, useGameStore } from "../game-store";

export const BackgroundScene = () => {
  const { isGameOver, background } = useGameStore();

  const { rive, RiveComponent } = useRive({
    src: riveFile,
    autoplay: true,
    artboard: "",
  });

  useEffect(() => {
    rive?.reset({ artboard: background, autoplay: true });
  }, [rive, background]);

  return (
    <>
      <RiveComponent width={window.innerWidth} height={window.innerHeight} />
      <Confetti
        recycle={true}
        run={true}
        numberOfPieces={isGameOver ? 300 : 0}
        wind={0}
        gravity={0.3}
        initialVelocityX={1.8}
        initialVelocityY={10}
        opacity={100}
        width={window.innerWidth}
        height={window.innerHeight}
      />
    </>
  );
};
