import { proxy, snapshot, useSnapshot } from "valtio";
import { IGameObject } from "../../common/models";
import { scenes } from "./game-data";

export const riveFile: string = "final_interactive_wall.riv";

interface IGameState {
  isGameOver: boolean;
  scene: string;
  background: string;
  allGameObjects: Array<IGameObject>;
}

const initialState: IGameState = {
  isGameOver: false,
  scene: "",
  background: "",
  allGameObjects: [],
};

const gameStore = proxy<IGameState>(initialState);

export const randomNumber = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const resetGame = () => {
  gameStore.isGameOver = false;
  const random = randomNumber(0, scenes.length - 1);
  const game = scenes[random];
  gameStore.allGameObjects = game.allGameObjects;
  gameStore.scene = game.scene;
  gameStore.background = game.background;

  gameStore.allGameObjects.forEach((item) => {
    item.isFound = false;
  });
};

export const useGameStore = () => {
  return useSnapshot(gameStore);
};

export const checkFinishGame = () => {
  const isOver = isGameOver();
  const snap = snapshot(gameStore);

  if (isOver) {
    gtag("event", "GameOver", {
      app_name: "Interactive Wall",
      screen_name: snap.scene,
    });
  }

  gameStore.isGameOver = isOver;
};

export const isGameOver = () => {
  const store = snapshot(gameStore);
  const gameObjects = store.allGameObjects;
  const items = gameObjects.filter((x) => x.isFound);
  return items.length === gameObjects.length;
};

export const updateFound = (name: string) => {
  const { allGameObjects } = gameStore;
  for (let index = 0; index < allGameObjects.length; index++) {
    const element = allGameObjects[index];

    if (element.artboard === name) {
      element.isFound = true;
      break;
    }
  }

  checkFinishGame();
};
