import { FC, useEffect, useState } from "react";

const totalSeconds = 10;

interface IProp {
  onComplete: () => void;
}

export const ResetCounter: FC<IProp> = (props) => {
  const [counter, setCounter] = useState(totalSeconds);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  useEffect(() => {
    const newIntervalId = setInterval(() => {
      setCounter((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
    return () => clearInterval(newIntervalId);
  }, []);

  if (counter === 0) {
    props.onComplete();
    clearInterval(intervalId);
  }

  return (
    <div className="bg-white/20 rounded-3xl backdrop-blur-md w-[112px] h-[112px] text-4xl font-bold text-white flex justify-center items-center">
      {counter}
    </div>
  );
};
